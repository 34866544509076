/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable security/detect-object-injection */
import type {
  BatchAutoRequest,
  BatchManualRequest,
  BatchReportResponse,
  Entity,
  ScoreSettings,
} from '../../types/batch-reports';
import type { ReportTypeEnum } from '../../types/global';
import {
  BatchLoanUploadRequest,
  SingleLoanUploadRequest,
} from '../../types/portfolio';
import type {
  BatchReportEntity,
  CsvReport,
  CsvReportUploadHeaders,
} from '../../types/report';
import { getCurrencyByIsoCode } from '../settings/settings.settings';
import { ISO } from './constants';
import {
  isCsvDataBatchLoan,
  isCsvDataSingleLoan,
  makeEntitiesForBatchLoan,
  makeEntitiesForCompanyLoans,
} from './portfolio-report-helpers';
import { makeUploadReportReqBody } from './report-helpers';

type UploadRequest =
  | BatchAutoRequest
  | BatchManualRequest
  | BatchLoanUploadRequest
  | SingleLoanUploadRequest;

export const makeEntitiesForManualBatch = (
  csvData: CsvReport,
  csvValues: string[][]
): BatchReportEntity[] => {
  // map unique company names as properties and set the index occurences as its values
  // eg { 'Company 1': [1,3,6,9] <- index used later }
  const companyNameAndIndexes: Record<string, number[]> = {};

  csvData.details_name.forEach((name, i) => {
    let arr = [];
    for (let j = 0; j < csvData.details_name.length; j++) {
      if (name === csvData.details_name[j]) {
        arr.push(j);
      }
    }
    companyNameAndIndexes[name] = arr;
  });

  const companyNameAndDataMap: Record<
    string,
    {
      [key in CsvReportUploadHeaders]: string[];
    }
  > = {};

  // for each company, iterate over the indexArr and use that index to get the correct data
  Object.entries(companyNameAndIndexes).forEach(
    ([companyName, indexArr], i) => {
      // iterate through the csvData's properties (details_name, currency etc)
      Object.entries(csvData).forEach(([header, values]) => {
        // and use the index to get the right value
        // indexArr => [1,4,6,8]
        const companyValues = indexArr.map(index => values[index]);

        companyNameAndDataMap[companyName] = {
          ...companyNameAndDataMap[companyName],
          [header]: companyValues,
        };
      });
    }
  );

  // the final step is to use the  filtered csvValues with the makeUploadReportReqBody used for report upload
  // this creates the entity field required by the API
  const entities = Object.entries(companyNameAndDataMap).map(
    ([key, company]) => {
      const filteredCsv = csvValues.filter(c => c.includes(key));
      return makeUploadReportReqBody(company, filteredCsv);
    }
  );

  return entities;
};

interface ConvertCsvProps {
  csvData: CsvReport;
  csvValues: string[][];
  name: string;
  uploadType: ReportTypeEnum;
  accounts_type?: number;
  currency?: string;
  productType?: string;
  score_settings?: ScoreSettings;
}

export const convertCSVToRequestBody = ({
  csvData,
  csvValues,
  name,
  uploadType,
  accounts_type = 1,
  currency,
  productType,
  score_settings,
}: ConvertCsvProps): UploadRequest => {
  // BASIC INFO
  let response = {
    name: name,
    accounts_type,
    // currency,
    entities: [],
    product_type: productType,
    score_settings,
  } as UploadRequest;

  // handle setting currency by ISO
  const hasCurrency = !!currency && currency !== ISO;

  if (uploadType === 'BATCH_LOAN' && isCsvDataBatchLoan(csvData)) {
    // check type of csvData
    const entities = makeEntitiesForBatchLoan(csvData);

    return {
      entities,
    };
  }

  if (uploadType === 'SINGLE_LOAN' && isCsvDataSingleLoan(csvData)) {
    const entities = makeEntitiesForCompanyLoans(csvData);

    return {
      entities,
    };
  }

  if (uploadType === 'BATCH_AUTO') {
    const entities = csvData?.company_id?.map(
      (id, i) =>
        ({
          company_id: id?.trim(),
          iso_code: csvData.iso_code[Number(i)]?.trim(),
          currency: hasCurrency
            ? currency
            : getCurrencyByIsoCode(csvData.iso_code[Number(i)]?.trim(), 'GBP'),
        } as Entity)
    );

    response.entities = entities || [];
  }

  if (uploadType === 'BATCH_MANUAL') {
    const entities = makeEntitiesForManualBatch(csvData, csvValues);
    response.entities = entities || [];
  }

  return response;
};

function calculateHoursBetweenDates(begin: Date | string, end: number) {
  const date1 = new Date(begin);
  const date2 = new Date(end);
  const diff = (date2.getTime() - date1.getTime()) / 1000;
  return diff / 3600;
}

export const isJobFailed = (job: BatchReportResponse) => {
  return (
    (job.created_at !== job.updated_at || job.finished_at) &&
    job.total_reports === job.failed_reports &&
    !job.cancelled_at
  );
};

export const isJobComplete = (job: BatchReportResponse) => {
  return (
    job.total_reports !== job.failed_reports &&
    job.total_reports !== null &&
    job.finished_at &&
    !job.cancelled_at
  );
};

export const isJobPending = (job: BatchReportResponse) => {
  return (
    !job.finished_at &&
    (job.failed_reports === null || job.failed_reports === 0) &&
    calculateHoursBetweenDates(job.created_at, Date.now()) < 24 &&
    !job.cancelled_at
  );
};

export const isJobCancelled = (job: BatchReportResponse) => {
  return !!job.cancelled_at;
};
