/**
 * @param {number} absolute
 * @returns number (float)
 */
export const convertAbsoluteValue = (absolute: number): number => {
  // - If 56% → 56 absolute
  // - If 34 absolute → 34%
  // - If 402 absolute → 100%
  // - If -56% → -56 absolute
  // - If -34 absolute → -34%
  // - If -402 absolute → -100%
  // - if 0 absolute → 0%

  if (absolute >= 100) {
    return 100;
  }
  if (absolute <= -100) {
    return -100;
  }
  // https://eslint.org/docs/latest/rules/no-compare-neg-zero
  if (Object.is(absolute, -0)) {
    return 0;
  }
  return absolute;
};

export const formatNumber = (number = 0, locale = 'en') => {
  return Intl.NumberFormat(locale, {
    style: 'decimal',
    maximumFractionDigits: 2,
  }).format(number);
};

interface FormatPercentOptions {
  locale?: string;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
}

export const formatPercent = (
  value: number,
  options: FormatPercentOptions = {
    locale: 'en',
    maximumFractionDigits: 2,
  }
) => {
  const { locale, maximumFractionDigits, minimumFractionDigits } = options;
  return Intl.NumberFormat(locale, {
    style: 'percent',
    maximumFractionDigits,
    minimumFractionDigits,
  }).format(value);
};

export const formatDecimalFromPercent = (
  value: number,
  options: FormatPercentOptions = {
    locale: 'en',
    maximumFractionDigits: 2,
  }
) => {
  const { locale, maximumFractionDigits } = options;
  return Intl.NumberFormat(locale, {
    style: 'decimal',
    maximumFractionDigits,
  }).format(value);
};
